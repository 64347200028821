<template>
  <div
    v-if="dialog && dialog.isOpen"
    class="dialog-view"
    @click.self.prevent="close"
  >
    <component
      :id="dialog.id"
      :is="dynamicDialog(dialog.componentName)"
      @close="close"
    />
  </div>
</template>

<script setup lang="ts">
import SignUpDialog from '../../cgbank/sign-up-dialog.vue'
import InputParameterDialog from '../../generate/input-bar/input-parameter-dialog.vue'
import ArtifactDetail from './artifact-detail.vue'
import ArtifactRemoveDialog from './artifact-remove.vue'
import Download from './download.vue'
import SubscriptionChange from './subscription-change.vue'
import { dialogComponentEnum } from '~/constants/dialog'

const { id } = defineProps<{ id: string }>()

const { dialogs, closeDialog, deleteDialog } = useDialogStore()
const dialog = computed(() => dialogs.find(dialog => dialog.id === id))

const dynamicDialog = (componentName: dialogComponentEnum) => {
  switch (componentName) {
    case dialogComponentEnum.inputParameter:
      return InputParameterDialog
    case dialogComponentEnum.artifactDetail:
      return ArtifactDetail
    case dialogComponentEnum.artifactRemove:
      return ArtifactRemoveDialog
    case dialogComponentEnum.downloadImage:
      return Download
    case dialogComponentEnum.signUp:
      return SignUpDialog
    case dialogComponentEnum.subscriptionChange:
      return SubscriptionChange
    default:
      return null
  }
}

const close = () => {
  closeDialog(id)
}

watchEffect(() => {
  if (dialog?.value && !dialog.value.isOpen) {
    deleteDialog(id)
  }
})
</script>

<style lang="scss" scoped>
.dialog-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: v-bind('`${dialog?.zIndex}`');
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
